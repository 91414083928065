
$secondary: #6667ab;

/* Base
-------------------------------------------------- */
/* lexend-deca-200 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-300 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-800 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/lexend-deca-v21-latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

html {
  min-height: 100%;
}


.error-label {
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 1.2em;
  color: #dc3545;
}

.ql-tooltip {
  z-index: 9999;
    left: 0 !important;
}

/* Sign In
-------------------------------------------------- */

.logo-sign-in {
  width: 400px;
}


.sign-in-form {
  margin-top: 12px;
  margin-bottom: 12px;
}

.sign-in-already {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
}
.sign-in-already > a {
  color: $secondary;
  text-decoration: none;
}

.sign-in-card > .card-header,
.sign-in-card > .card-footer {
  background-color: white;
  border: none;
}

/* NavBar
-------------------------------------------------- */
.logo-navbar {
  width: 140px;
}

.user-image-navbar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* Footer
-------------------------------------------------- */
footer {
  background-color: #dcd7d4;
  div {
    font-size: 1.15 rem;
    display: inline-block;
    font-weight: 400;
    //line-height: 1.155rem;
  }
}

.footer-column {
  padding: 24px;
}


.ql-editor{
  min-height: 300px ;
}

.ql-container,  {
  font-family: "Lexend Deca",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";
  font-size: 18px;
}

.ql-toolbar.ql-snow {
  font-family:  "Lexend Deca",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol";
  line-height: 1.5;
}



.full-height {
  height: 100%;
}